exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-dynamics-index-tsx": () => import("./../../../src/pages/company-dynamics/index.tsx" /* webpackChunkName: "component---src-pages-company-dynamics-index-tsx" */),
  "component---src-pages-company-dynamics-mdx-slug-tsx": () => import("./../../../src/pages/company-dynamics/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-company-dynamics-mdx-slug-tsx" */),
  "component---src-pages-components-footer-info-index-tsx": () => import("./../../../src/pages/components/footerInfo/index.tsx" /* webpackChunkName: "component---src-pages-components-footer-info-index-tsx" */),
  "component---src-pages-components-global-footer-index-tsx": () => import("./../../../src/pages/components/globalFooter/index.tsx" /* webpackChunkName: "component---src-pages-components-global-footer-index-tsx" */),
  "component---src-pages-components-global-header-index-tsx": () => import("./../../../src/pages/components/globalHeader/index.tsx" /* webpackChunkName: "component---src-pages-components-global-header-index-tsx" */),
  "component---src-pages-components-header-banner-index-tsx": () => import("./../../../src/pages/components/headerBanner/index.tsx" /* webpackChunkName: "component---src-pages-components-header-banner-index-tsx" */),
  "component---src-pages-components-homepage-slick-index-tsx": () => import("./../../../src/pages/components/homepageSlick/index.tsx" /* webpackChunkName: "component---src-pages-components-homepage-slick-index-tsx" */),
  "component---src-pages-components-layout-index-tsx": () => import("./../../../src/pages/components/layout/index.tsx" /* webpackChunkName: "component---src-pages-components-layout-index-tsx" */),
  "component---src-pages-components-logo-icon-index-tsx": () => import("./../../../src/pages/components/logoIcon/index.tsx" /* webpackChunkName: "component---src-pages-components-logo-icon-index-tsx" */),
  "component---src-pages-components-page-text-index-tsx": () => import("./../../../src/pages/components/pageText/index.tsx" /* webpackChunkName: "component---src-pages-components-page-text-index-tsx" */),
  "component---src-pages-components-qr-index-tsx": () => import("./../../../src/pages/components/qr/index.tsx" /* webpackChunkName: "component---src-pages-components-qr-index-tsx" */),
  "component---src-pages-components-seo-index-tsx": () => import("./../../../src/pages/components/seo/index.tsx" /* webpackChunkName: "component---src-pages-components-seo-index-tsx" */),
  "component---src-pages-components-show-box-slick-index-tsx": () => import("./../../../src/pages/components/showBoxSlick/index.tsx" /* webpackChunkName: "component---src-pages-components-show-box-slick-index-tsx" */),
  "component---src-pages-components-yz-breadcrumbs-index-tsx": () => import("./../../../src/pages/components/yzBreadcrumbs/index.tsx" /* webpackChunkName: "component---src-pages-components-yz-breadcrumbs-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-real-scene-landings-index-tsx": () => import("./../../../src/pages/real-scene-landings/index.tsx" /* webpackChunkName: "component---src-pages-real-scene-landings-index-tsx" */),
  "component---src-pages-teams-index-1-tsx": () => import("./../../../src/pages/teams/index1.tsx" /* webpackChunkName: "component---src-pages-teams-index-1-tsx" */),
  "component---src-pages-teams-index-tsx": () => import("./../../../src/pages/teams/index.tsx" /* webpackChunkName: "component---src-pages-teams-index-tsx" */),
  "component---src-pages-works-collection-index-tsx": () => import("./../../../src/pages/works-collection/index.tsx" /* webpackChunkName: "component---src-pages-works-collection-index-tsx" */),
  "component---src-pages-works-collection-mdx-slug-tsx": () => import("./../../../src/pages/works-collection/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-works-collection-mdx-slug-tsx" */),
  "component---src-pages-yinzun-info-index-tsx": () => import("./../../../src/pages/yinzun-info/index.tsx" /* webpackChunkName: "component---src-pages-yinzun-info-index-tsx" */)
}

